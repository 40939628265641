<template>
  <validation-observer
    ref="studentReserveSaveFormRef"
    #default="{invalid}"
  >
    <b-modal
      id="studentReserveSaveModal"
      body-class="position-static"
      size="lg"
      centered
      :title="'Thêm mới bảo lưu'"
      @show="onShow"
      @hide="onHide"
    >
      <b-form>
        <b-row>
          <b-col cols="12">
            <b-form-group label-for="student_id">
              <template v-slot:label>
                Sinh viên <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Sinh viên"
                rules="required"
              >
                <v-select
                  v-model="targetStudentReserve.student_id"
                  :options="studentOptions"
                  :reduce="option => option.value"
                />
                <b-form-invalid-feedback :state="getElementState(errors)">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <b-form-group label-for="name">
              <template v-slot:label>
                Quyết định bảo lưu <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Quyết định bảo lưu"
                rules="required"
              >
                <b-form-input
                  id="name"
                  v-model="targetStudentReserve.name"
                  name="name"
                  placeholder="Nhập số quyết định bảo lưu"
                  :state="getElementState(errors)"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="6">
            <b-form-group
              label="Thời gian bắt đầu"
              label-for="time_start"
            >
              <flat-pickr
                v-model="targetStudentReserve.timeStart"
                :config="config"
                class="form-control"
                placeholder="Thời gian bắt đầu"
                name="start_at"
              />

            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
              label="Thời gian kết thúc"
              label-for="time_end"
            >
              <flat-pickr
                v-model="targetStudentReserve.timeEnd"
                :config="config"
                class="form-control"
                placeholder="Thời gian kết thúc"
                name="expire_date"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <b-form-group label-for="courseSemester">
              <template v-slot:label>
                Kỳ học <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Kỳ học"
                rules="required"
              >
                <b-form-checkbox-group
                  v-model="targetStudentReserve.course_semester_ids"
                  class=""
                  :options="dataCheckBox"
                  :state="getElementState(errors)"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
      <template #modal-footer>
        <div class="w-100 d-flex justify-content-end">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            :disabled="invalid"
            @click="onSave()"
          >
            <span class="text-right">
              <feather-icon icon="CheckIcon" /> Lưu lại
            </span>
          </b-button>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-secondary"
            @click="$bvModal.hide('studentReserveSaveModal')"
          >
            <span class="text-right">
              <feather-icon icon="XIcon" /> Hủy
            </span>
          </b-button>
        </div>
      </template>
      <b-overlay
        no-wrap
        variant="white"
        spinner-variant="primary"
        blur="0"
        opacity=".75"
        rounded="sm"
        :show="isLoading"
      /></b-modal>
  </validation-observer>
</template>

<script>
import {
  BForm, BFormGroup, BFormInput, BModal, BOverlay, BFormInvalidFeedback, BButton, BCol, BRow, BFormCheckboxGroup,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import vSelect from 'vue-select'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import { vn } from 'flatpickr/dist/l10n/vn.js'
import flatPickr from 'vue-flatpickr-component'

export default {
  name: 'StudentReserveSave',
  components: {
    BFormGroup,
    BFormInput,
    BModal,
    BForm,
    BOverlay,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    flatPickr,
    BCol,
    BRow,
    BFormCheckboxGroup,
  },
  directives: {
    Ripple,
  },
  props: {
    propsData: {
      type: Object,
      default: () => ({
        student_id: null,
        class_id: null,
        name: '',
        soBaoLuu: '',
        timeStart: null,
        timeEnd: null,
        course_semester_ids: [],
      }),
    },
  },
  data() {
    return {
      isLoading: false,
      targetStudentReserve: {
        student_id: null,
        class_id: null,
        name: '',
        soBaoLuu: '',
        timeStart: null,
        timeEnd: null,
        course_semester_ids: [],
      },
      itemReserve: {
        course_semester_id: null,
        start_date: '',
        expire_date: '',
      },
      required,
      paginationOptions: {
        enabled: false,
      },
      config: {
        wrap: true,
        enableTime: false,
        altInput: true,
        altFormat: 'Y-m-d',
        dateFormat: 'Y-m-d',
        locale: vn,
        // minDate: 'today',
      },
      courseSemesterSelected: [],
      dataCheckBox: [],
      formData: {
      },
    }
  },
  computed: {
    ...mapGetters({
      studentClass: 'studentReserve/studentClass',
      dataCourseSemester: 'studentReserve/dataCourseSemester',
    }),
    columns() {
      return [
        {
          label: 'Học kỳ',
          field: 'name',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Từ ngày',
          field: 'start_date',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Đến ngày',
          field: 'expire_date',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
      ]
    },
    studentOptions() {
      return this.studentClass.map(item => ({ value: item.id, label: item.name }))
    },
  },
  methods: {
    ...mapActions({
      getStudentClass: 'studentReserve/getDataStudentClass',
      getCourseSemesterClass: 'studentReserve/getDataCourseSemesterClass',
      createStudentReserve: 'studentReserve/createStudentReserveV1',
    }),
    getElementState(errors) {
      return errors.length > 0 ? false : null
    },
    async onShow() {
      if (this.propsData.student_id) {
        this.targetStudentReserve = { ...this.propsData }
      }
      await Promise.all([
        this.getStudentClass({ class_id: this.propsData.class_id }),
        this.getCourseSemesterClass({ class_id: this.propsData.class_id, typeId: 1 }),
      ])
      this.dataCheckBox = this.dataCourseSemester.map(item => ({
        text: item.name,
        value: item.course_semester_id,
      }))
    },
    onHide() {
      this.$refs.studentReserveSaveFormRef.reset()
      this.targetStudentReserve = {
        name: '',
        course_semester_id: null,
        student_class_id: null,
        start_date: '',
        expire_date: '',
        reason: '',
      }
    },

    async onSave() {
      const valid = this.$refs.studentReserveSaveFormRef.validate()
      if (valid) {
        this.isLoading = true
        try {
          if (this.targetStudentReserve.course_semester_ids.length === 0) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Chưa chọn học kỳ bảo lưu',
                icon: 'XCircleIcon',
                variant: 'danger',
              },
            })
            return
          }
          const response = await this.createStudentReserve(
            {
              student_class_id: this.targetStudentReserve.student_id,
              course_semester_ids: this.targetStudentReserve.course_semester_ids,
              start_date: this.targetStudentReserve.timeStart,
              expire_date: this.targetStudentReserve.timeEnd,
              name: this.targetStudentReserve.name,
              status: 1,
              reason: '',
            },
          )
          if (response) {
            const { isSuccessful, message } = response
            if (isSuccessful) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Tạo bảo lưu thành công',
                  icon: 'CheckIcon',
                  variant: 'success',
                },
              })
              this.$emit('succeed')
              this.$bvModal.hide('studentReserveSaveModal')
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: `${message}`,
                  icon: 'XCircleIcon',
                  variant: 'danger',
                },
              })
            }
          }
        } catch (e) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `[${e.code}] ${e.message}`,
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        } finally {
          this.isLoading = false
        }
      }
    },

    valTime(dateStr, startDate) {
      if (dateStr < startDate) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Thời gian kết thúc phải lớn hơn bắt đầu!',
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      }
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
